// Token Actions
export const TOKEN_REFRESH = 'TOKEN_REFRESH';

// Login actions
export const LOGIN_BEGIN = 'LOGIN_BEGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_CLEAN = 'LOGIN_CLEAN';

// Logout actions
export const LOGOUT_BEGIN = 'LOGOUT_BEGIN';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

// Environment actions
export const ENVIRONMENT_RELOAD_BEGIN = 'ENVIRONMENT_RELOAD_BEGIN';
export const ENVIRONMENT_RELOAD_SUCCESS = 'ENVIRONMENT_RELOAD_SUCCESS';
export const ENVIRONMENT_RELOAD_FAILURE = 'ENVIRONMENT_RELOAD_FAILURE';

//User actions
export const DELETE_USER_INFO_LOGOUT = 'DELETE_USER_INFO_LOGOUT';
export const DELETE_USER_INFO_EXPIRED_SESSION =
  'DELETE_USER_INFO_EXPIRED_SESSION';

export const USER_BEGIN = 'USER_BEGIN';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';

export const ALL_USERS_MIN_DATA_BEGIN = 'ALL_USERS_MIN_DATA_BEGIN';
export const ALL_USERS_MIN_DATA_SUCCESS = 'ALL_USERS_MIN_DATA_SUCCESS';
export const ALL_USERS_MIN_DATA_FAILURE = 'ALL_USERS_MIN_DATA_FAILURE';

//Entities actions
export const CORE_ENTITIES_BEGIN = 'CORE_ENTITIES_BEGIN';
export const CORE_ENTITIES_SUCCESS = 'CORE_ENTITIES_SUCCESS';
export const CORE_ENTITIES_FAILURE = 'CORE_ENTITIES_FAILURE';

//Services actions
export const SERVICE_SET_IS_EDIT_PARAM = 'SET_IS_EDIT_PARAM';

export const SERVICE_FETCH_BEGIN = 'SERVICE_FETCH_BEGIN';
export const SERVICE_FETCH_SUCCESS = 'SERVICE_FETCH_SUCCESS';
export const SERVICE_FETCH_FAILURE = 'SERVICE_FETCH_FAILURE';

export const GET_SERVICE_UPDATES_BEGIN = 'GET_SERVICE_UPDATES_BEGIN';
export const GET_SERVICE_UPDATES_FINISHED = 'GET_SERVICE_UPDATES_FINISHED';

export const SERVICE_SAVE_BEGIN = 'SERVICE_SAVE_BEGIN';
export const SERVICE_SAVE_SUCCESS = 'SERVICE_SAVE_SUCCESS';
export const SERVICE_SAVE_FAILURE = 'SERVICE_SAVE_FAILURE';
export const SERVICE_SAVE_FAILURE_INVOKERS = 'SERVICE_SAVE_FAILURE_INVOKERS';

export const SERVICE_REFRESH = 'SERVICE_REFRESH';
export const SERVICE_UPDATE = 'SERVICE_UPDATE';
export const SERVICE_PATCH = 'SERVICE_PATCH';
export const SERVICE_DELETE = 'SERVICE_DELETE';
export const SERVICE_DELETE_SUCCESS = 'SERVICE_DELETE_SUCCESS';
export const SERVICE_DELETE_FAILURE = 'SERVICE_DELETE_FAILURE';
export const DISCARD_DELETE_SERVICE = 'DISCARD_DELETE_SERVICE';

export const SERVICE_ACTIVE_FILTER_UPDATE = 'SERVICE_ACTIVE_FILTER_UPDATE';

export const SERVICE_FROM_INSTRUCTION_FETCH_BEGIN =
  'SERVICE_FROM_INSTRUCTION_FETCH_BEGIN';
export const SERVICE_FROM_INSTRUCTION_FETCH_SUCCESS =
  'SERVICE_FROM_INSTRUCTION_FETCH_SUCCESS';
export const SERVICE_FROM_INSTRUCTION_FETCH_FAILURE =
  'SERVICE_FROM_INSTRUCTION_FETCH_FAILURE';
export const SERVICE_FROM_INSTRUCTION_FETCH_DELETE =
  'SERVICE_FROM_INSTRUCTION_FETCH_DELETE';

export const SEARCH_SERVICE_VARIABLES = 'SEARCH_SERVICE_LOCAL_VARIABLES';
export const SEARCH_SERVICE_PARAMETERS_VARIABLES =
  'SEARCH_SERVICE_PARAMETERS_VARIABLES';

export const SERVICES_SEARCH_BEGIN = 'SERVICES_SEARCH_BEGIN';
export const SERVICES_SEARCH_SUCCESS = 'SERVICES_SEARCH_SUCCESS';
export const SERVICES_SEARCH_FAILURE = 'SERVICES_SEARCH_FAILURE';
export const SERVICES_SEARCH_CLEAN = 'SERVICES_SEARCH_CLEAN';

export const SERVICES_ALL_PROJECTS_BY_CONCATENATED_NAME_EXCLUDED_CURRENT_PROJECT =
  'SERVICES_ALL_PROJECTS_BY_CONCATENATED_NAME_EXCLUDED_CURRENT_PROJECT';

// Service Creation
export const SERVICE_MIN_SEARCH_BEGIN = 'SERVICE_MIN_SEARCH_BEGIN';
export const SERVICE_MIN_SEARCH_SUCCESS = 'SERVICE_MIN_SEARCH_SUCCESS';
export const SERVICE_MIN_SEARCH_FAILURE = 'SERVICE_MIN_SEARCH_FAILURE';
export const SERVICE_MIN_SEARCH_CLEAN = 'SERVICE_MIN_SEARCH_CLEAN';

export const SERVICE_MIN_VERB_FETCH_BEGIN = 'SERVICE_MIN_VERB_FETCH_BEGIN';
export const SERVICE_MIN_VERB_FETCH_SUCCESS = 'SERVICE_MIN_VERB_FETCH_SUCCESS';
export const SERVICE_MIN_VERB_FETCH_FAILURE = 'SERVICE_MIN_VERB_FETCH_FAILURE';

export const SERVICE_MIN_ENTITY_FETCH_BEGIN = 'SERVICE_MIN_ENTITY_FETCH_BEGIN';
export const SERVICE_MIN_ENTITY_FETCH_SUCCESS =
  'SERVICE_MIN_ENTITY_FETCH_SUCCESS';
export const SERVICE_MIN_ENTITY_FETCH_FAILURE =
  'SERVICE_MIN_ENTITY_FETCH_FAILURE';

export const SERVICE_MIN_PROJECT_FETCH_BEGIN =
  'SERVICE_MIN_PROJECT_FETCH_BEGIN';
export const SERVICE_MIN_PROJECT_FETCH_SUCCESS =
  'SERVICE_MIN_PROJECT_FETCH_SUCCESS';
export const SERVICE_MIN_PROJECT_FETCH_FAILURE =
  'SERVICE_MIN_PROJECT_FETCH_FAILURE';

export const SERVICE_MIN_MODULE_FETCH_BEGIN = 'SERVICE_MIN_MODULE_FETCH_BEGIN';
export const SERVICE_MIN_MODULE_FETCH_SUCCESS =
  'SERVICE_MIN_MODULE_FETCH_SUCCESS';
export const SERVICE_MIN_MODULE_FETCH_FAILURE =
  'SERVICE_MIN_MODULE_FETCH_FAILURE';
export const SERVICE_CREATION_TAB_CHANGE = 'SERVICE_CREATION_TAB_CHANGE';

export const SERVICE_CREATION_BEGIN = 'SERVICE_CREATION_BEGIN';
export const SERVICE_CREATION_SUCCESS = 'SERVICE_CREATION_SUCCESS';
export const SERVICE_CREATION_FAILURE = 'SERVICE_CREATION_FAILURE';
export const SERVICE_CREATION_CLEAN = 'SERVICE_CREATION_CLEAN';

export const LAST_OPENED_SERVICES_ADD = 'LAST_OPENED_SERVICES_ADD';
export const LAST_OPENED_SERVICES_DELETE = 'LAST_OPENED_SERVICES_DELETE';
export const LAST_OPENED_SERVICES_PATCH = 'LAST_OPENED_SERVICES_PATCH';
export const LAST_OPENED_SERVICES_NAMELIST_ADD =
  'LAST_OPENED_SERVICES_NAMELIST_ADD';
export const LAST_OPENED_SERVICES_NAMELIST_REMOVE =
  'LAST_OPENED_SERVICES_NAMELIST_REMOVE';
export const LAST_OPENED_SERVICES_NAMELIST_PATCH =
  'LAST_OPENED_SERVICES_NAMELIST_PATCH';
export const LAST_OPENED_SERVICES_ACTIVE_FILTER_UPDATE =
  'LAST_OPENED_SERVICES_ACTIVE_FILTER_UPDATE';

export const SERVICE_INVOKERS_FETCH_BEGIN = 'SERVICE_INVOKERS_FETCH_BEGIN';
export const SERVICE_INVOKERS_FETCH_SUCCESS = 'SERVICE_INVOKERS_FETCH_SUCCESS';
export const SERVICE_INVOKERS_FETCH_FAILURE = 'SERVICE_INVOKERS_FETCH_FAILURE';

export const SERVICE_INVOKERS_CLEAN_DATA = 'SERVICE_INVOKERS_CLEAN_DATA';

export const SERVICE_ISSUES_FETCH_BEGIN = 'SERVICE_ISSUES_FETCH_BEGIN';
export const SERVICE_ISSUES_FETCH_SUCCESS = 'SERVICE_ISSUES_FETCH_SUCCESS';
export const SERVICE_ISSUES_FETCH_FAILURE = 'SERVICE_ISSUES_FETCH_FAILURE';

export const SERVICE_HISTORY_FETCH_BEGIN = 'SERVICE_HISTORY_FETCH_BEGIN';
export const SERVICE_HISTORY_FETCH_SUCCESS = 'SERVICE_HISTORY_FETCH_SUCCESS';
export const SERVICE_HISTORY_FETCH_FAILURE = 'SERVICE_HISTORY_FETCH_FAILURE';

export const ISSUES_FETCH_BEGIN = 'ISSUES_FETCH_BEGIN';
export const ISSUES_FETCH_SUCCESS = 'ISSUES_FETCH_SUCCESS';
export const ISSUES_FETCH_FAILURE = 'ISSUES_FETCH_FAILURE';

export const COPY_INSTRUCTIONS = 'COPY_INSTRUCTIONS';
export const SET_SELECTED_INSTRUCTIONS = 'SET_SELECTED_INSTRUCTIONS';
export const SET_IS_CUT = 'SET_IS_CUT';
export const SET_PASTE_BELOW = 'SET_PASTE_BELOW';
export const COPY_FORMAL_PARAMETER = 'COPY_FORMAL_PARAMETER';

export const ISSUE_TRANSACTION_BEGIN = 'ISSUE_TRANSACTION_BEGIN';
export const ISSUE_TRANSACTION_SUCCESS = 'ISSUE_TRANSACTION_SUCCESS';
export const ISSUE_TRANSACTION_FAILURE = 'ISSUE_TRANSACTION_FAILURE';

export const ERRORS_FETCH_BEGIN = 'ERRORS_FETCH_BEGIN';
export const ERRORS_FETCH_SUCCESS = 'ERRORS_FETCH_SUCCESS';
export const ERRORS_FETCH_FAILURE = 'ERRORS_FETCH_FAILURE';
export const ERROR_LIST_ALL_BY_SERVICE_BEGIN = 'ERROR_LIST_ALL_BY_SERVICE_BEGIN';
export const ERROR_LIST_ALL_BY_SERVICE_SUCCESS = 'ERROR_LIST_ALL_BY_SERVICE_SUCCESS';
export const ERROR_LIST_ALL_BY_SERVICE_FAILURE = 'ERROR_LIST_ALL_BY_SERVICE_FAILURE';

export const MODULES_FETCH_BEGIN = 'MODULES_FETCH_BEGIN';
export const MODULES_FETCH_SUCCESS = 'MODULES_FETCH_SUCCESS';
export const MODULES_FETCH_FAILURE = 'MODULES_FETCH_FAILURE';

export const USERS_FETCH_BEGIN = 'USERS_FETCH_BEGIN';
export const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS';
export const USERS_FETCH_FAILURE = 'USERS_FETCH_FAILURE';

export const PROFILES_FETCH_BEGIN = 'PROFILES_FETCH_BEGIN';
export const PROFILES_FETCH_SUCCESS = 'PROFILES_FETCH_SUCCESS';
export const PROFILES_FETCH_FAILURE = 'PROFILES_FETCH_FAILURE';

export const TRANSITIONS_FETCH_BEGIN = 'TRANSITIONS_FETCH_BEGIN';
export const TRANSITIONS_FETCH_SUCCESS = 'TRANSITIONS_FETCH_SUCCESS';
export const TRANSITIONS_FETCH_FAILURE = 'TRANSITIONS_FETCH_FAILURE';

export const ENTITIES_FETCH_BEGIN = 'ENTITIES_FETCH_BEGIN';
export const ENTITIES_FETCH_SUCCESS = 'ENTITIES_FETCH_SUCCESS';
export const ENTITIES_FETCH_FAILURE = 'ENTITIES_FETCH_FAILURE';

export const SERVICE_MODULES_FETCH_BEGIN = 'SERVICE_MODULES_FETCH_BEGIN';
export const SERVICE_MODULES_FETCH_SUCCESS = 'SERVICE_MODULES_FETCH_SUCCESS';
export const SERVICE_MODULES_FETCH_FAILURE = 'SERVICE_MODULES_FETCH_FAILURE';

export const PROJECTS_FETCH_BEGIN = 'PROJECTS_FETCH_BEGIN';
export const PROJECTS_FETCH_SUCCESS = 'PROJECTS_FETCH_SUCCESS';
export const PROJECTS_FETCH_FAILURE = 'PROJECTS_FETCH_FAILURE';

export const SERVICE_PROJECTS_FETCH_BEGIN = 'SERVICE_PROJECTS_FETCH_BEGIN';
export const SERVICE_PROJECTS_FETCH_SUCCESS = 'SERVICE_PROJECTS_FETCH_SUCCESS';
export const SERVICE_PROJECTS_FETCH_FAILURE = 'SERVICE_PROJECTS_FETCH_FAILURE';

export const SERVICE_ENTITIES_FETCH_BEGIN = 'SERVICE_ENTITIES_FETCH_BEGIN';
export const SERVICE_ENTITIES_FETCH_SUCCESS = 'SERVICE_ENTITIES_FETCH_SUCCESS';
export const SERVICE_ENTITIES_FETCH_FAILURE = 'SERVICE_ENTITIES_FETCH_FAILURE';

export const ERROR_TRANSACTION_BEGIN = 'ERROR_TRANSACTION_BEGIN';
export const ERROR_TRANSACTION_SUCCESS = 'ERROR_TRANSACTION_SUCCESS';
export const ERROR_TRANSACTION_FAILURE = 'ERROR_TRANSACTION_FAILURE';

export const ENTITY_TRANSACTION_BEGIN = 'ENTITY_TRANSACTION_BEGIN';
export const ENTITY_TRANSACTION_SUCCESS = 'ENTITY_TRANSACTION_SUCCESS';
export const ENTITY_TRANSACTION_FAILURE = 'ENTITY_TRANSACTION_FAILURE';

export const MODULE_TRANSACTION_BEGIN = 'MODULE_TRANSACTION_BEGIN';
export const MODULE_TRANSACTION_SUCCESS = 'MODULE_TRANSACTION_SUCCESS';
export const MODULE_TRANSACTION_FAILURE = 'MODULE_TRANSACTION_FAILURE';

export const PROJECT_TRANSACTION_BEGIN = 'PROJECT_TRANSACTION_BEGIN';
export const PROJECT_TRANSACTION_SUCCESS = 'PROJECT_TRANSACTION_SUCCESS';
export const PROJECT_TRANSACTION_FAILURE = 'PROJECT_TRANSACTION_FAILURE';

export const USER_TRANSACTION_BEGIN = 'USER_TRANSACTION_BEGIN';
export const USER_TRANSACTION_SUCCESS = 'USER_TRANSACTION_SUCCESS';
export const USER_TRANSACTION_FAILURE = 'USER_TRANSACTION_FAILURE';
export const USER_TRANSACTION_ABORTED = 'USER_TRANSACTION_ABORTED';

export const PROFILES_TRANSACTION_BEGIN = 'PROFILES_TRANSACTION_BEGIN';
export const PROFILES_TRANSACTION_SUCCESS = 'PROFILES_TRANSACTION_SUCCESS';
export const PROFILES_TRANSACTION_FAILURE = 'PROFILES_TRANSACTION_FAILURE';

export const SERVICE_OVERRIDE_ERROR = 'SERVICE_OVERRIDE_ERROR';
export const SERVICE_DISCARD_OVERRIDE_ERROR_BEGIN =
  'SERVICE_DISCARD_OVERRIDE_ERROR_BEGIN';
export const SERVICE_DISCARD_OVERRIDE_ERROR_END =
  'SERVICE_DISCARD_OVERRIDE_ERROR_END';

export const CLEAR_ERROR_WRAP = 'CLEAR_ERROR_WRAP';

//Reports actions
export const REPORT_GET_BEGIN = 'REPORT_GET_BEGIN';
export const REPORT_GET_SUCCESS = 'REPORT_GET_SUCCESS';
export const REPORT_GET_FAILURE = 'REPORT_GET_FAILURE';

//Swagger actions
export const SWAGGER_GET_BEGIN = 'SWAGGER_GET_BEGIN';
export const SWAGGER_GET_SUCCESS = 'SWAGGER_GET_SUCCESS';
export const SWAGGER_GET_FAILURE = 'SWAGGER_GET_FAILURE';
export const SWAGGER_GET_CLEAN = 'SWAGGER_GET_CLEAN';

export const ADD_INSTRUCTION_BEGIN = 'ADD_INSTRUCTION_BEGIN';
export const ADD_INSTRUCTION_FAILURE = 'ADD_INSTRUCTION_FAILURE';

//Parameters Services
export const FETCH_ENTITIES_PARAMETER_BEGIN = 'FETCH_ENTITIES_PARAMETER_BEGIN';
export const FETCH_ENTITIES_PARAMETER_SUCCESS =
  'FETCH_ENTITIES_PARAMETER_SUCCESS';
export const FETCH_ENTITIES_PARAMETERS_FAILURE =
  'FETCH_ENTITIES_PARAMETERS_FAILURE';

export const ADD_LOCAL_VARIABLE = 'ADD_LOCAL_VARIABLE';
export const ADD_LOCAL_VARIABLES = 'ADD_LOCAL_VARIABLES';
export const CLEAR_LOCAL_VARIABLES = 'CLEAR_LOCAL_VARIABLES';

export const UPDATE_ASSIGNED_USER = 'UPDATE_ASSIGNED_USER';
export const UPDATE_ASSIGNED_ISSUE = 'UPDATE_ASSIGNED_ISSUE';

export const STATUS_TRANSITION_BEGIN = 'STATUS_TRANSITION_BEGIN';
export const STATUS_TRANSITION_SUCCESS = 'STATUS_TRANSITION_SUCCESS';
export const STATUS_TRANSITION_FAILURE = 'STATUS_TRANSITION_FAILURE';

export const OBTAIN_GLBS_BEGIN = 'OBTAIN_GLBS_BEGIN';
export const OBTAIN_GLBS_SUCCESS = 'OBTAIN_GLBS_SUCCESS';
export const OBTAIN_GLBS_FAILURE = 'OBTAIN_GLBS_FAILURE';

export const ACCESSIBILITY_MODALS_IS_OPEN = 'ACCESSIBILITY_MODALS_IS_OPEN';

export const ISSUE_MANDATORY_PARAMETER_OFF = 'ISSUE_MANDATORY_PARAMETER_OFF';
export const ISSUE_MANDATORY_PARAMETER_ON = 'ISSUE_MANDATORY_PARAMETER_ON';
export const UPDATE_MODAL_ISSUE = 'UPDATE_MODAL_ISSUE';
export const ISSUE_MANDATORY_REQUIRED = 'ISSUE_MANDATORY_REQUIRED';