import { combineReducers } from 'redux';

import loginReducer from './loginReducer';
import userReducer from './userReducer';
import serviceReducer from './serviceReducer';
import lastOpenedServicesReducer from './lastOpenedServicesReducer';
import servicesSearchReducer from './servicesSearchReducer';
import swaggerReducer from './swaggerReducer';
import parentServiceReducer from './parentServiceReducer';
import lastOpenedServicesNameListReducer from './lastOpenedServicesNameListReducer';
import coreEntitiesReducer from './coreEntitiesReducer';
import coreEntityReducer from './coreEntityReducer';
import serviceInvokersReducer from './serviceInvokersReducer';
import serviceHistoryReducer from './serviceHistoryReducer';
import serviceIssuesReducer from './serviceIssuesReducer';
import errorsReducer from './errorsReducer';
import modulesReducer from './modulesReducer';
import entitiesReducer from './entitiesReducer';
import projectsReducer from './projectsReducer';
import serviceModulesReducer from './serviceModulesReducer';
import serviceProjectsReducer from './serviceProjectsReducer';
import issuesReducer from './issuesReducer';
import minServiceSearchReducer from './minServiceSearchReducer';
import serviceCreationDataReducer from './serviceCreationDataReducer';
import glbEntitiesReducer from './glbEntitiesReducer';
import servicesEntitiesReducer from './servicesEntitiesReducer';
import usersDataReducer from './usersDataReducer';
import usersReducer from './usersReducer';
import profilesReducer from './profilesReducer';
import transitionsReducer from './transitionsReducer';
import copyInstructionsReducer from './copyInstructionsReducer';
import accessibilityReducer from './accessibilityReducer';
import copyFormalParameterReducer from './copyFormalParameterReducer';
import issueMandatoryReducer from './issueMandatoryReducer';

export default combineReducers({
  loginFlow: combineReducers({
    loginData: loginReducer,
    userData: userReducer,
  }),
  serviceFlow: combineReducers({
    currentService: serviceReducer,
    parentService: parentServiceReducer,
    openedServices: lastOpenedServicesReducer,
    openedServicesHistory: lastOpenedServicesNameListReducer,
    accessibility: accessibilityReducer,
  }),
  APIFlow: combineReducers({
    usersData: usersDataReducer,
    foundServices: servicesSearchReducer,
    minServiceFound: minServiceSearchReducer,
    serviceCreationData: serviceCreationDataReducer,
    swaggerData: swaggerReducer,
    coreEntitiesData: coreEntitiesReducer,
    coreEntity: coreEntityReducer,
    serviceInvokers: serviceInvokersReducer,
    serviceHistory: serviceHistoryReducer,
    serviceIssues: serviceIssuesReducer,
    copiedInstructions: copyInstructionsReducer,
    copiedFormalParameter: copyFormalParameterReducer,
    issues: issuesReducer,
    users: usersReducer,
    profiles: profilesReducer,
    transitions: transitionsReducer,
    errors: errorsReducer,
    modules: modulesReducer,
    entities: entitiesReducer,
    serviceModules: serviceModulesReducer,
    projects: projectsReducer,
    serviceProjects: serviceProjectsReducer,
    serviceEntities: servicesEntitiesReducer,
    glbs: glbEntitiesReducer,
    issueMandatory: issueMandatoryReducer,
  }),
});
