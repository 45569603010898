import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { persistor, store } from './store';
import './components/I18n/I18n';
import './sass/main.scss';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

/**
 * This lazy import section is made for improving performance,
 * applying the concept of code-splitting and reducing bigger chunk
 * load at runtime.
 */
const ProfilesManager = lazy(() => import('./components/Abm/ProfilesManager'));
const UsersManager = lazy(() => import('./components/Abm/UsersManager'));
const EntityDetail = lazy(() =>
  import('./components/Abm/Entities/EntityDetail')
);
const App = lazy(() => import('./components/App'));
const Login = lazy(() => import('./components/Login/NewLogin'));
const Workspace = lazy(() => import('./components/Workspace/Workspace'));
const ErrorsManager = lazy(() => import('./components/Abm/ErrorsManager'));
const ModulesManager = lazy(() => import('./components/Abm/ModulesManager'));
const EntitiesManager = lazy(() =>
  import('./components/Abm/Entities/EntitiesManager')
);
const ProjectsManager = lazy(() => import('./components/Abm/ProjectsManager'));
const IssueManager = lazy(() => import('./components/Abm/IssueManager'));
const Reports = lazy(() => import('./components/Reports/Reports'));
const BranchManager = lazy(() => import('./components/Abm/BranchManager'));
const ServiceCreation = lazy(() =>
  import('./components/ServiceCreation/ServiceCreation')
);
const ServiceParameterEdit = lazy(() =>
  import(
    './components/ServiceWorkspace/ServiceParametersList/ServiceParameters/ServiceParameterEdit/ServiceParameterEdit'
  )
);
const ServiceEdit = lazy(() =>
  import('./components/ServiceWorkspace/ServiceEdit/ServiceEdit')
);
const ErrorManager = lazy(() =>
  import('./components/ServiceWorkspace/ErrorHandler/ErrorManager')
);
const ErrorOverrideManager = lazy(() =>
  import(
    './components/ServiceWorkspace/ErrorHandler/ErrorOverride/ErrorOverrideManager'
  )
);
const ErrorWrapManager = lazy(() =>
  import(
    './components/ServiceWorkspace/ErrorHandler/WrapError/ErrorWrapManager'
  )
);
const InvokersList = lazy(() =>
  import('./components/ServiceWorkspace/Invokers/InvokersList/InvokersList')
);
const History = lazy(() =>
  import('./components/ServiceWorkspace/History/History')
);
const AdvancedSearch = lazy(() => import('./components/AdvancedSearchPage'));
const SwaggerPage = lazy(() => import('./components/SwaggerPage'));
const ErrorsListByService = lazy(() =>
  import('./components/ServiceWorkspace/ErrorHandler/ErrorsListByService')
);
const SyntacticComparator = lazy(() => import('./components/SyntacticComparator/SyntacticComparatorForm'));
const Page403 = lazy(() => import('./components/Page403'));
const Page404 = lazy(() => import('./components/Page404'));
const Page500 = lazy(() => import('./components/Page500'));

//development only
window.axios = axios;

const history = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<React.Fragment />} persistor={persistor}>
      <Router history={history}>
        <Suspense
          fallback={
            <div className="loading-spinner">
              <Spin className="loader" indicator={<LoadingOutlined />} />
            </div>
          }
        >
          <Switch>
            <Route path="/login" exact strict render={() => <Login />} />
            <PrivateRoute path="/home" exact strict render={() => <App />} />
            <PrivateRoute
              path="/errorManager"
              exact
              strict
              render={() => <ErrorsManager />}
            />
            <PrivateRoute
              path="/moduleManager"
              exact
              strict
              render={() => <ModulesManager />}
            />
            <PrivateRoute
              path="/entityManager"
              exact
              strict
              render={() => <EntitiesManager />}
            />
            <PrivateRoute
              path="/entity/:id"
              exact
              strict
              render={(params) => <EntityDetail entityId={Number(params.id)} />}
            />
            <PrivateRoute
              path="/projectsManager"
              exact
              strict
              render={() => <ProjectsManager />}
            />
            <PrivateRoute
              path="/usersManager"
              exact
              strict
              render={() => <UsersManager />}
            />
            <PrivateRoute
              path="/profilesManager"
              exact
              strict
              render={() => <ProfilesManager />}
            />
            <PrivateRoute
              path="/issueManager"
              exact
              strict
              render={() => <IssueManager />}
            />
            <PrivateRoute
              path="/reports"
              exact
              strict
              render={() => <Reports />}
            />
            <PrivateRoute
              path="/branchManager"
              exact
              strict
              render={() => <BranchManager />}
            />
            <PrivateRoute
              path="/new"
              exact
              strict
              render={() => <ServiceCreation />}
            />
            <PrivateRoute
              path="/syntacticComparator"
              exact
              strict
              render={() => <SyntacticComparator />}
            />
            <PrivateRoute
              path="/duplicate/:serviceName/:branch/:projectSearch/:typeSearch"
              exact
              strict
              sensitive
              render={(params) => (
                <ServiceCreation
                  serviceName={params.serviceName}
                  branch={params.branch}
                  projectSearch={params.projectSearch}
                  typeSearch={params.typeSearch}
                />
              )}
            />
            <PrivateRoute
              path="/service/:serviceName/:project/:branch"
              exact
              strict
              sensitive
              render={(params) => {
                return (
                  <Workspace
                    serviceName={params.serviceName}
                    project={params.project}
                    branch={params.branch}
                  />
                );
              }}
            />
            <PrivateRoute
              path="/service/:serviceName/:project/:branch/version/:version"
              exact
              strict
              sensitive
              render={(params) => {
                return (
                  <Workspace
                    serviceName={params.serviceName}
                    project={params.project}
                    branch={params.branch}
                    versionHistory={params.version}
                  />
                );
              }}
            />
            <PrivateRoute
              path="/service/:serviceName/:project/:branch/serviceParamEdit"
              exact
              strict
              render={(params) => (
                <ServiceParameterEdit
                  serviceName={params.serviceName}
                  project={params.project}
                  branch={params.branch}
                />
              )}
            />
            <PrivateRoute
              path="/service/:serviceName/:project/:branch/version/:version/serviceParamEdit"
              exact
              strict
              render={(params) => (
                <ServiceParameterEdit
                  serviceName={params.serviceName}
                  project={params.project}
                  branch={params.branch}
                  versionHistory={params.version}
                />
              )}
            />
            <PrivateRoute
              path="/service/:serviceName/:project/:branch/invokers"
              exact
              strict
              render={(params) => (
                <InvokersList
                  serviceName={params.serviceName}
                  project={params.project}
                  branch={params.branch}
                />
              )}
            />
            <PrivateRoute
              path="/service/:serviceName/:project/:branch/:id/history"
              exact
              strict
              render={(params) => (
                <History
                  serviceName={params.serviceName}
                  project={params.project}
                  branch={params.branch}
                  id={Number(params.id)}
                />
              )}
            />
            <PrivateRoute
              path="/service/:serviceName/:project/:branch/errorManager"
              exact
              strict
              render={() => <ErrorManager />}
            />
            <PrivateRoute
              path="/service/:serviceName/:project/:branch/serviceEdit"
              exact
              strict
              render={(params) => (
                <ServiceEdit
                  branch={params.branch}
                  serviceName={params.serviceName}
                  project={params.project}
                />
              )}
            />
            <PrivateRoute
              path="/service/:serviceName/:project/:branch/errorOverrideManager/:primitiveName/:primitiveId"
              exact
              strict
              render={(params) => (
                <ErrorOverrideManager
                  project={params.project}
                  primitiveName={params.primitiveName}
                  primitiveId={params.primitiveId.startsWith("_tmp") ? params.primitiveId : Number(params.primitiveId)}
                />
              )}
            />
            <PrivateRoute
              path="/service/:serviceName/:project/:branch/errorWrapManager/:primitiveName/:primitiveId"
              exact
              strict
              render={(params) => (
                <ErrorWrapManager
                  project={params.project}
                  primitiveName={params.primitiveName}
                  primitiveId={params.primitiveId.startsWith("_tmp") ? params.primitiveId : Number(params.primitiveId)}
                />
              )}
            />
            <PrivateRoute
              path="/advancedSearch"
              exact
              strict
              render={() => <AdvancedSearch />}
            />
            <PrivateRoute
              path="/service/swagger/:serviceName/:project/:language"
              // path="/service/swagger"
              exact
              strict
              render={(params) => (
                <SwaggerPage
                  serviceName={params.serviceName}
                  project={params.project}
                  language={params.language}
                />
              )}
            />
            <PrivateRoute
              path="/service/:serviceName/:project/:branch/:type/errorsList"
              exact
              strict
              render={(params) => (
                <ErrorsListByService
                  serviceName={params.serviceName}
                  project={params.project}
                  branch={params.branch}
                  type={params.type}
                />
              )}
            />
            <PrivateRoute path="/page403" render={() => <Page403 />} />
            <PrivateRoute path="/page404" render={() => <Page404 />} />
            <PrivateRoute path="/page500" render={() => <Page500 />} />
            <PrivateRoute render={() => <Redirect to="/home" />} />
          </Switch>
        </Suspense>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
